import badge from './images/sapporo-badge-blue.png';
import SapporoCircle from './images/sapporo-circle.png';
import SapporoCircleBlack from './images/Sapporo-Circle-Black.png';
import SapporoCircleWhite from './images/Sapporo-Circle-White.png';

export const getImage = (name: string) => {
  if (name === 'badge') {
    return {uri: badge};
  } else if (name === 'sapporo-circle') {
    return {uri: SapporoCircle};
  } else if (name === 'Sapporo-Circle-Black') {
    return {uri: SapporoCircleBlack};
  } else if (name === 'Sapporo-Circle-White') {
    return {uri: SapporoCircleWhite};
  }
};
