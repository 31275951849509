"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SapporoQueryOrderBy = exports.SapproStartAfter = exports.SapporoQueryLimit = exports.SapporoWhereQuery = exports.SapporoQuery = void 0;
class SapporoQuery {
    constructor(path) {
        this.path = path;
    }
    addWhere(where) {
        if (this.wheres) {
            this.wheres.push(where);
        }
        else {
            this.wheres = [where];
        }
    }
}
exports.SapporoQuery = SapporoQuery;
class SapporoWhereQuery {
    constructor(fld, comp, val) {
        this.field = fld;
        this.comparator = comp;
        this.value = val;
    }
}
exports.SapporoWhereQuery = SapporoWhereQuery;
class SapporoQueryLimit {
    constructor(l) {
        this.limit = l;
    }
}
exports.SapporoQueryLimit = SapporoQueryLimit;
class SapproStartAfter {
    constructor(t) {
        this.token = t;
    }
}
exports.SapproStartAfter = SapproStartAfter;
class SapporoQueryOrderBy {
    constructor(fld, dir) {
        this.field = fld;
        this.direction = dir;
    }
}
exports.SapporoQueryOrderBy = SapporoQueryOrderBy;
