"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SapporoUser = void 0;
class SapporoUser {
    constructor(createdAt, uid, displayName, email, emailVerified, phoneNumber, photoURL, defaultLocation, isAnonymous, hideAds) {
        this.createdAt = createdAt;
        this.uid = uid;
        this.displayName = displayName;
        this.email = email;
        this.emailVerified = emailVerified;
        this.isAnonymous = !!isAnonymous;
        this.phoneNumber = phoneNumber;
        this.photoURL = photoURL;
        this.hideAds = hideAds || false;
        this.defaultLocation = defaultLocation
            ? {
                latitude: defaultLocation.latitude,
                longitude: defaultLocation.longitude,
            }
            : undefined;
    }
}
exports.SapporoUser = SapporoUser;
