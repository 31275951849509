import React, {useState} from 'react';
import {LatLong} from '@sapporogo/core/SapporoClaim';
import {Image, Box} from 'native-base';
import GoogleMapReact from 'google-map-react';
import {getImage} from './getImage'

type AnyReactComponentProps = {
  text: string;
  lat: number;
  lng: number;
};
const markerSize = 50

const MarkerComponent = ({text, lat, lng}: AnyReactComponentProps) => (
  <Box flex={1} w={markerSize} h={markerSize}>
    <Image
      source={getImage('badge')}
      resizeMode="contain"
      size='sm'
      shadow={5}
      alt="The Sapporo"
    />
  </Box>
);


const MapView = ({location, marker, locationUpdated}: any) => {
  // const [ bounds, setBounds ] = useState<any>(undefined);
  // const [ size, setSize ] = useState<any>(undefined);
  const [ markerLocation, setMarkerLocation ] = useState<any>(location || marker);
  const [ showMarker, setShowMarker ] = useState<boolean>(markerLocation);

  // useEffect( () => {
  //   setMarkerLocation
  // })
  const getMarkerLocation = (center: any, bounds: any, size: any) => {
    if ( bounds && size ) {
      const mult = 10000000;
      const { ne, nw, sw } = bounds;
      const width =  Math.floor(Math.abs( ne.lng * mult - nw.lng * mult ));
      const height = Math.floor(Math.abs( nw.lat * mult - sw.lat * mult ));
      const longPerPix = Math.floor(width/size.width);
      const latPerPix = Math.floor(height/size.height);

      // now move the location by 50% of the marker size both ways
      if ( locationUpdated ) {
        const newMarkerLocation = {
          latitude: center.lat + (latPerPix*.5*markerSize)/mult,
          longitude: center.lng - (longPerPix*.5*markerSize)/mult,
        }
        if ( newMarkerLocation.latitude && newMarkerLocation.longitude ) {
          locationUpdated( { latitude: center.lat, longitude: center.lng })
          return newMarkerLocation; 
        } else {
          return markerLocation;
        }
      } else {
        const newMarkerLocation = {
          latitude: location.latitude + (latPerPix*.5*markerSize)/mult,
          longitude: location.longitude - (longPerPix*.5*markerSize)/mult,
        }
        return newMarkerLocation; 
      }
    }
    return markerLocation;
  }

  const onMapChange = ({center, zoom, bounds, size, ...otherMapProps}: any) => {
    setMarkerLocation(getMarkerLocation(center, bounds,size))
  }
  
  const handleApiLoaded = (map:any, maps:any) => {
    // use map and maps objects
    //console.log('MAP API LOADED', !!map, !!maps);
  };

  const props = {
    center: {
      lat: location.latitude,
      lng: location.longitude,
    },
    zoom: 11,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{height: '50vh', width: '90%'}}>
      <GoogleMapReact
        onZoomAnimationStart={() => {setShowMarker(false)}}
        onZoomAnimationEnd={() => {setShowMarker(true)}}
        onChange={onMapChange}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        bootstrapURLKeys={{key: 'AIzaSyBuVpwfFaLmEEVeMEfMK853xXmM2nJJ1aE'}}
        defaultCenter={props.center}
        defaultZoom={props.zoom}
      >
        { (showMarker && !!markerLocation) && (<MarkerComponent
          lat={markerLocation.latitude}
          lng={markerLocation.longitude}
          text="Claim around here"
        />)}
      </GoogleMapReact>
    </div>
  );


}

export const getLocationView = (location: LatLong ) => {
    return getLocationViewWithEdit( location, location, undefined )
}


export const getLocationViewWithEdit = (location: LatLong, markerLocation: LatLong, locationUpdated?: (location: any) => any  ): any => {
  return ( <MapView location={location} marker={markerLocation} locationUpdated={locationUpdated} /> )
};
