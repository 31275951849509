"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGraphData = exports.humanizeLabel = exports.processData = void 0;
const processData = (data) => {
    const makeDataArray = (d) => {
        return Object.keys(d).map(key => {
            return { x: key, y: d[key] / 100 };
        });
    };
    return data.map((datum) => makeDataArray(datum));
};
exports.processData = processData;
const humanizeLabel = (str) => {
    if (str === 'spirit') {
        return 'Style';
    }
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
};
exports.humanizeLabel = humanizeLabel;
const getGraphData = (data) => {
    const keys = data && data.length > 0 ? Object.keys(data[0]) : [];
    return {
        maxima: 100,
        data: (0, exports.processData)(data),
        keys,
    };
};
exports.getGraphData = getGraphData;
