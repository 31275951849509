import React, {useState, useEffect} from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Sidebar from './Sidebar';
import {
  extendTheme,
  HStack,
  Center,
  NativeBaseProvider,
} from 'native-base';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import Dashboard from '@sapporogo/core/Dashboard';
import SapporoHistory from '@sapporogo/core/SapporoHistory';
import ClaimHistory from '@sapporogo/core/ClaimHistory';
import Splash from '@sapporogo/core/Splash';
import WelcomePage from '@sapporogo/core/WelcomePage';
import {getImage} from './getImage';
import {getUser, UserContext} from '@sapporogo/core/auth/getUser';
import {fullFirebase} from './Firebase';
import WebClaimSapporoForm from './WebClaimSapporoForm';
import MessageHandler from '@sapporogo/core/MessageHandler';
import {SapporoUser} from '@sapporogo/core/SapporoUser';
import About from '@sapporogo/core/About';
import ReactMarkdown from 'react-markdown';
import * as victory from 'victory';
import {getLocationViewWithEdit} from './MapMaker';
import {getLocationView} from './MapMaker';
import LocationView from '@sapporogo/core/LocationView';
import Firebase from '@sapporogo/core/Firebase';
import {backgroundSecondary, backgroundPrimary} from '@sapporogo/core/UI';
import ReactionList from '@sapporogo/core/ReactionList';
import SapporoHeader from './SapporoHeader';

const queryClient = new QueryClient();

const theme = extendTheme({
  suppressColorAccessibilityWarning: true,
  fontConfig: {
    Montserrat: {
      100: {
        normal: 'Montserrat',
        bold: 'Montserrat',
        italic: 'Montserrat-LightItalic',
      },
      200: {
        normal: 'Montserrat',
        bold: 'Montserrat',
        italic: 'Montserrat-Light-Italic',
      },
      300: {
        normal: 'Montserrat-Thin',
        bold: 'Montserrat',
        italic: 'Montserrat-Light-Italic',
      },
      400: {
        normal: 'Montserrat',
        bold: 'Montserrat',
        italic: 'Montserrat-Italic',
      },
      500: {
        normal: 'Montserrat-Medium',
        bold: 'Montserrat',
      },
      600: {
        normal: 'Montserrat-Medium',
        bold: 'Montserrat',
        italic: 'Montserrat-MediumItalic',
      },
      700: {
        bold: 'Montserrat',
        normal: 'MontserratNext',
      },
      800: {
        normal: 'MontserratNext',
        bold: 'Montserrat',
        italic: 'MontserratNextItalic',
      },
      900: {
        normal: 'MontserratNext',
        bold: 'Montserrat',
        italic: 'MontserratNextItalic',
      },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
    mono: 'Montserrat',
  },
});

const App = () => {
  const userLabel = 'Sapporo-User';
  const [user, setUser] = useState<SapporoUser | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [showInitPage, setShowInitPage] = useState<boolean>(false);
  const [goAnonymous, setGoAnonymous] = useState<boolean>(false);

  const getUIComponents = () => {
    return {
      background: undefined,
      backgroundPrimary,
      backgroundSecondary,
    };
  };

  useEffect(() => {
    fullFirebase.listenOnAuthStateChanged((userState: any) => {
      const currentSetUser = localStorage.getItem(userLabel);
      if (!userState && !currentSetUser) {
        if (goAnonymous) {
          getUser(fullFirebase, userState, true).then(
            async (sUser: SapporoUser) => {
              //await versions.init();
              setUser(sUser);
              setShowInitPage(false);
              if (loading) {
                setLoading(false);
                if (sUser && sUser.uid) {
                  localStorage.setItem(userLabel, sUser.uid);
                }
              }
              return;
            },
          );
        } else {
          setLoading(false);
          setShowInitPage(true);
        }
      } else if (userState && !currentSetUser) {
        // if we have a userstate but no currentSetUser
        localStorage.setItem(userLabel, userState.uid);
        getUser(fullFirebase, userState, false).then(
          async (sUser: SapporoUser) => {
            setUser(sUser);
            setShowInitPage(false);
            if (loading) {
              setLoading(false);
              if (sUser && sUser.uid) {
                localStorage.setItem(userLabel, sUser.uid);
              }
            }
          },
        );
        setShowInitPage(false);
      } else if (currentSetUser && !userState) {
        // we are not who we were last time
        localStorage.removeItem(userLabel);
        setShowInitPage(true);
        setLoading(false);
      } else {
        // we have both userState and currentSet User, get full user
        getUser(fullFirebase, userState, false).then(
          async (sUser: SapporoUser) => {
            //await versions.init();
            setUser(sUser);
            setShowInitPage(false);
            if (loading) {
              setLoading(false);
              if (sUser && sUser.uid) {
                localStorage.setItem(userLabel, sUser.uid);
              }
            }
            return;
          },
        );
      }
    });
  }, [loading, goAnonymous]);

  const getLocation = () => {
    const geoLabel = 'geolocation';
    const currentLocation = localStorage.getItem(geoLabel);

    // if we have a saved location, use it
    if (currentLocation && currentLocation.split.length === 2) {
      const vals = currentLocation.split(':');
      return new Promise(resolve => {
        resolve({
          latitude: parseFloat(vals[0]),
          longitude: parseFloat(vals[1]),
        });
      });
    }

    if (!navigator.geolocation) {
      console.log('No Geolocation');
      return new Promise(resolve => {
        resolve(undefined);
      });
    } else {
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          position => {
            const newLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            console.log('LOCATION SET', newLocation);
            if (newLocation && newLocation.latitude && newLocation.longitude) {
              localStorage.setItem(
                geoLabel,
                `${newLocation.latitude}:${newLocation.longitude}`,
              );
            }
            resolve(newLocation);
          },
          error => {
            console.log('Unable to retrieve your location', error);
            resolve({
              latitude: 45,
              longitude: -93,
            });
          },
        );
      });
    }
  };

  const onGoClick = () => {
    console.log('ON GO!');
    setGoAnonymous(true);
  };

  if (loading) {
    return (
      <NativeBaseProvider theme={theme}>
        <Splash spinner getUIComponents={getUIComponents} getImage={getImage} />
      </NativeBaseProvider>
    );
  }

  if (showInitPage) {
    return (
      <Firebase firebase={fullFirebase}>
        <UserContext.Provider value={user}>
          <NativeBaseProvider theme={theme}>
            <WelcomePage onGo={onGoClick} getImage={getImage} />
          </NativeBaseProvider>
        </UserContext.Provider>
      </Firebase>
    );
  }

  return (
    <Firebase firebase={fullFirebase}>
      <UserContext.Provider value={user}>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <ReactionList>
            <MessageHandler enabled={true}>
              <NativeBaseProvider theme={theme}>
                <LocationView
                  locationGetter={getLocation}
                  getUIComponents={getUIComponents}
                  getImage={getImage}>
                  <ClaimHistory user={user}>
                    <BrowserRouter>
                      <Center
                        minWidth={600}
                        maxWidth={1200}
                        h={'100%'}
                        w={'100%'}
                        flex={1}
                        p={3}>
                        <SapporoHeader
                          getImage={getImage}
                          getUIComponents={getUIComponents}
                        />
                        <HStack flex={1}>
                          <Routes>
                            <Route
                              path="/"
                              element={
                                <Dashboard
                                  victory={victory}
                                  appIsActive={true}
                                  getImage={getImage}
                                  getUIComponents={getUIComponents}
                                  getLocationView={getLocationView}
                                  getLocationViewWithEdit={
                                    getLocationViewWithEdit
                                  }
                                />
                              }
                            />
                            <Route
                              path="/history"
                              element={
                                <SapporoHistory
                                  getUIComponents={getUIComponents}
                                  getLocationView={getLocationView}
                                  victory={victory}
                                  appIsActive={true}
                                  getImage={getImage}
                                />
                              }
                            />
                            <Route
                              path="/about"
                              element={
                                <About
                                  getUIComponents={getUIComponents}
                                  getImage={getImage}
                                  getMarkdown={(body: string) => {
                                    return (
                                      <ReactMarkdown className="markdownComponent">
                                        {body}
                                      </ReactMarkdown>
                                    );
                                  }}
                                />
                              }
                            />
                            <Route
                              path="/claim"
                              element={
                                <WebClaimSapporoForm
                                  asModal={false}
                                  getUIComponents={getUIComponents}
                                  victory={victory}
                                  getImage={getImage}
                                  user={user}
                                  showClaimSapporo={true}
                                  setShowClaimSapporo={() => {
                                    console.log('NOOP HERE in Claim');
                                  }}
                                  getLocationViewWithEdit={
                                    getLocationViewWithEdit
                                  }
                                />
                              }
                            />
                          </Routes>
                        </HStack>
                      </Center>
                    </BrowserRouter>
                  </ClaimHistory>
                </LocationView>
                <ReactQueryDevtools initialIsOpen={false} />
              </NativeBaseProvider>
            </MessageHandler>
          </ReactionList>
        </QueryClientProvider>
      </UserContext.Provider>
    </Firebase>
  );
};

/*

          <Box
            bg={backgroundSecondary}
            minHeight="100vh"
            justifyContent="center"
            px={4}
          >
            <Center>
              <Box flex={1} maxWidth={650} m={5}>
              <Dashboard victory={victory} appIsActive={true} firebase={fullFirebase} getImage={getImage} getLocationView={getLocationView} getLocationViewWithEdit={getLocationViewWithEdit}/>
              </Box>
            </Center>
          </Box>


              <ClaimSapporoForm
                  showClaimSapporo={true}
                  setShowClaimSapporo={(b:boolean) => {
                    console.log('GO TOGGLE')
                  }}
                  actionListener={( status: string, message:string ) => { 
                    console.log('Action Listener', status, message)
                  }}
                  location={{ latitude: 45, longitude: -93 } }
                  user={user}
                  victory={victory}
                getImage={getImage}
                getLocationViewWithEdit={getLocationViewWithEdit}
              />


              


                


<SapporoClaimView
getLocationView={getLocationView}
getImage={getImage}
passedReactions={[]}
passedUser={tempUser}
claim={tempClaim}
victory={victory}
active={true}
saveLikeStatus={(
  claimID: string,
  userID: string,
  like: boolean,
  value: number,
) => {
  console.log('NEW SCORE SAVE', claimID, userID, like, value);
}}
refreshClaim={() => {
  return new Promise<any>(resolve => {
    console.log('We did this');
    resolve('we done');
  });
}}
/> */

export default App;
