"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClaimHistory = exports.populateReaction = exports.populateUsers = exports.getSapporoClaimFromDoc = exports.subscribeToHistory = exports.likeCollection = exports.claimCollection = void 0;
const SapporoClaim_1 = require("../SapporoClaim");
const SapporoUser_1 = require("../SapporoUser");
const luxon_1 = require("luxon");
const QueryTypes_1 = require("./QueryTypes");
exports.claimCollection = 'claims';
exports.likeCollection = 'likes';
const subscribeToHistory = (firebase, onUpdate) => {
    return firebase.subscribeToCollection(exports.claimCollection, onUpdate);
};
exports.subscribeToHistory = subscribeToHistory;
const getUser = (firebase, path) => __awaiter(void 0, void 0, void 0, function* () {
    if (!path) {
        return undefined;
    }
    let dbuser = yield firebase.getDoc(path);
    if (dbuser && dbuser.data.defaultLocation) {
        dbuser.data.defaultLocation = {
            latitude: dbuser.defaultLocation.latitude,
            longitude: dbuser.defaultLocation.longitude,
        };
    }
    if (dbuser) {
        console.log('DBUSER', dbuser.data);
        const time = dbuser.data.createdAt && !!dbuser.data.createdAt.toJSDate ? dbuser.data.createdAt.toJSDate() : dbuser.data.createdAt.toDate();
        const user = new SapporoUser_1.SapporoUser(time, dbuser.id, dbuser.data.displayName, dbuser.data.email, dbuser.data.emailVerified, dbuser.data.phoneNumber, dbuser.data.photoURL, dbuser.data.defaultLocation, dbuser.data.isAnonymous, dbuser.data.hideAds);
        return user;
    }
    return undefined;
});
const getSapporoClaimFromDoc = (doc) => {
    const loc = {
        latitude: doc.data.location.latitude,
        longitude: doc.data.location.longitude,
    };
    const val = new SapporoClaim_1.SapporoClaim(doc.data.header, doc.data.description, loc, doc.data.idiocy, doc.data.spirit, doc.data.reach, doc.data.approved);
    if (doc.data.createdAt && !!doc.data.createdAt.toJSDate) {
        val.createdAt = luxon_1.DateTime.fromJSDate(doc.data.createdAt ? doc.data.createdAt.toJSDate() : new Date());
    }
    else if (doc.data.createdAt) {
        val.createdAt = luxon_1.DateTime.fromJSDate(doc.data.createdAt ? doc.data.createdAt.toDate() : new Date());
    }
    if (doc.data.approvedAt && !!doc.data.approvedAt.toJSDate) {
        val.approvedAt = luxon_1.DateTime.fromJSDate(doc.data.approvedAt.toJSDate());
    }
    else if (doc.data.approvedAt) {
        val.approvedAt = luxon_1.DateTime.fromJSDate(doc.data.approvedAt.toDate());
    }
    if (doc.data.activeStart && !!doc.data.activeStart.toJSDate) {
        val.activeStart = luxon_1.DateTime.fromJSDate(doc.data.activeStart.toJSDate());
    }
    else if (doc.data.activeStart) {
        val.activeStart = luxon_1.DateTime.fromJSDate(doc.data.activeStart.toDate());
    }
    if (doc.data.activeEnd && !!doc.data.activeEnd.toJSDate) {
        val.activeEnd = luxon_1.DateTime.fromJSDate(doc.data.activeEnd.toJSDate());
    }
    else if (doc.data.activeEnd) {
        val.activeEnd = luxon_1.DateTime.fromJSDate(doc.data.activeEnd.toDate());
    }
    if (doc.data.approvedBy) {
        val.approvedBy = doc.data.approvedBy;
    }
    val.reactionCount = doc.data.reactionCount || 0;
    val.reactionScore = doc.data.reactionScore || 0;
    val.id = doc.id;
    val.withdrawn = doc.data.withdrawn || false;
    val.userID = doc.data.user.path;
    return val;
};
exports.getSapporoClaimFromDoc = getSapporoClaimFromDoc;
const populateUsers = (firebase, claims) => __awaiter(void 0, void 0, void 0, function* () {
    for (const claim of claims) {
        const user = yield getUser(firebase, claim.userID);
        if (user) {
            claim.user = user;
        }
    }
});
exports.populateUsers = populateUsers;
const populateReaction = (firebase, userID, claims) => __awaiter(void 0, void 0, void 0, function* () {
    if (userID) {
        for (const claim of claims) {
            if (claim.id) {
                let query = new QueryTypes_1.SapporoQuery(`${exports.claimCollection}/${claim.id}/${exports.likeCollection}`);
                query.addWhere(new QueryTypes_1.SapporoWhereQuery('user', '==', firebase.docRef(`users/${userID}`)));
                // let pageToken;
                // let pullSize = 0;
                const queryResults = yield firebase.query(query);
                const { data, pageToken } = queryResults;
                const userLike = data && data.length > 0 ? data[0] : undefined;
                if (userLike) {
                    const userReaction = {
                        like: !!userLike,
                        score: userLike.data.score,
                    };
                    claim.userReaction = userReaction;
                }
            }
        }
    }
});
exports.populateReaction = populateReaction;
const getClaimHistory = (firebase, userID, limit = 10, startAfter = undefined) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        //firebase.query( claimCollection, [ new SapporoWhereQuery('withdrawn', '==', false )])
        let query = new QueryTypes_1.SapporoQuery(exports.claimCollection);
        query.addWhere(new QueryTypes_1.SapporoWhereQuery('withdrawn', '==', false));
        query.orderBy = new QueryTypes_1.SapporoQueryOrderBy('createdAt', 'desc');
        if (startAfter) {
            query.startAfter = new QueryTypes_1.SapproStartAfter(startAfter);
        }
        query.limit = new QueryTypes_1.SapporoQueryLimit(limit);
        // let pageToken;
        // let pullSize = 0;
        const queryResults = yield firebase.query(query);
        const { data, pageToken } = queryResults;
        const history = data.map((d) => (0, exports.getSapporoClaimFromDoc)(d));
        // add users
        yield (0, exports.populateUsers)(firebase, history);
        yield (0, exports.populateReaction)(firebase, userID, history);
        const newHistory = [...(history || [])];
        newHistory.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                const aDate = luxon_1.DateTime.fromJSDate(a.createdAt.toJSDate());
                const bDate = luxon_1.DateTime.fromJSDate(b.createdAt.toJSDate());
                if (aDate < bDate) {
                    return 1;
                }
                else if (aDate > bDate) {
                    return -1;
                }
                else {
                    return 0;
                }
            }
            else if (a.createdAt && !b.createdAt) {
                return -1;
            }
            else if (b.createdAt && !a.createdAt) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return {
            lastItemToken: pageToken,
            data: newHistory,
        };
    }
    catch (err) {
        console.log('FAIL', err);
        return undefined;
    }
});
exports.getClaimHistory = getClaimHistory;
exports.default = exports.getClaimHistory;
