// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: 'AIzaSyBLydoyaf5WJLRkDRk6707iLN63TuhzUZs',
    authDomain: 'sapporoguard.firebaseapp.com',
    projectId: 'sapporoguard',
    storageBucket: 'sapporoguard.appspot.com',
    messagingSenderId: '898729018069',
    appId: '1:898729018069:web:3984a19f02298556fa185c',
    measurementId: 'G-H0E5W3XRZC',
  };

export const vapidKey = 'BNMeCmce4PyZfYyOKKoNQa1qhKwtKSau4a_kRZYavNITjg_kKFabqm5kmmm5QDTYBfslwjSUl-CUhP04KISNb14';

export default firebaseConfig;
  