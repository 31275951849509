"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const native_base_1 = require("native-base");
const icons_1 = require("@native-base/icons");
const UI_1 = require("@sapporogo/core/UI");
const getUser_1 = require("@sapporogo/core/auth/getUser");
const Firebase_1 = require("@sapporogo/core/Firebase");
const AccountSignUp = (props) => {
    const user = (0, react_1.useContext)(getUser_1.UserContext);
    const firebase = (0, react_1.useContext)(Firebase_1.FirebaseContext);
    const initialFocusRef = (0, react_1.useRef)(null);
    const [showPassword, setShowPassword] = react_1.default.useState(false);
    const [open, setIsOpen] = (0, react_1.useState)(false);
    const [email, setEmail] = (0, react_1.useState)(undefined);
    const [password, setPassword] = (0, react_1.useState)(undefined);
    const [displayName, setDisplayName] = (0, react_1.useState)(undefined);
    const [emailError, setEmailError] = (0, react_1.useState)(undefined);
    const [displayNameError, setDisplayNameError] = (0, react_1.useState)(undefined);
    const [passwordError, setPasswordError] = (0, react_1.useState)(undefined);
    const [saving, setSaving] = (0, react_1.useState)(false);
    const submitSaveRequest = () => {
        console.log('Saving item request');
        if (displayName && email && password) {
            setSaving(true);
            console.log('Trying to save user with username', displayName, email);
            firebase
                .upgradeUser(user, displayName, email, password)
                .then((results) => {
                if (results.success) {
                    resetValues();
                    setSaving(false);
                }
                else {
                    console.log('PROBLEMS', results);
                    setSaving(false);
                }
            });
        }
        else {
            console.log('NOT SAVING ', displayName, email, password);
        }
        if (!email) {
            setEmailError('Email required');
        }
        if (!password) {
            setPasswordError('Password required');
        }
        if (!displayName) {
            setDisplayNameError('Display Name is required');
        }
    };
    const handleDisplayNameChange = (text) => {
        setDisplayName(text);
    };
    const handleEmailChange = (text) => {
        setEmail(text);
    };
    const handlePasswordChange = (text) => {
        setPassword(text);
    };
    const resetValues = () => {
        setEmail(undefined);
        setDisplayName(undefined);
        setPassword(undefined);
        setEmailError(undefined);
        setDisplayNameError(undefined);
        setPasswordError(undefined);
        setSaving(false);
    };
    const handleShowHide = () => {
        setShowPassword(!showPassword);
    };
    return (react_1.default.createElement(native_base_1.Box, { alignItems: "center" },
        react_1.default.createElement(native_base_1.Popover, { isOpen: open, placement: "bottom", initialFocusRef: initialFocusRef, trigger: triggerProps => {
                return (react_1.default.createElement(native_base_1.Pressable, Object.assign({}, triggerProps, { onPress: () => {
                        setIsOpen(true);
                    } }),
                    react_1.default.createElement(native_base_1.Badge, { size: "mini", bg: UI_1.backgroundPrimary, _text: {
                            fontFamily: 'Montserrat',
                            color: 'white',
                        }, borderWidth: 1, borderColor: '#FFFFFF', variant: "solid", fontFamily: 'Montserrat', rounded: "4" }, "Sign up +")));
            } },
            react_1.default.createElement(native_base_1.Popover.Content, { minWidth: "500", borderWidth: 2, borderColor: UI_1.backgroundPrimary, shadow: 5 },
                react_1.default.createElement(native_base_1.Popover.Header, { _text: {
                        fontFamily: 'Montserrat',
                    } }, "Create Account"),
                react_1.default.createElement(native_base_1.Popover.Body, null,
                    react_1.default.createElement(native_base_1.FormControl, { mt: "3" },
                        react_1.default.createElement(native_base_1.FormControl.Label, { _text: {
                                fontSize: 'xs',
                                fontFamily: 'Montserrat',
                                fontWeight: 'medium',
                            } }, "Display Name"),
                        react_1.default.createElement(native_base_1.Input, { isInvalid: !!displayNameError, rounded: "sm", fontSize: "xs", onChange: () => { }, onChangeText: handleDisplayNameChange, InputLeftElement: react_1.default.createElement(native_base_1.Icon, { as: react_1.default.createElement(icons_1.AntDesign, { name: "user" }), size: 5, ml: "2", color: "muted.400" }), placeholder: "Display Name" }),
                        !!user && (react_1.default.createElement(native_base_1.FormControl.HelperText, null,
                            "Current: ", user === null || user === void 0 ? void 0 :
                            user.displayName)),
                        !!displayNameError && (react_1.default.createElement(native_base_1.FormControl.ErrorMessage, null,
                            displayNameError,
                            "xxx"))),
                    react_1.default.createElement(native_base_1.FormControl, { mt: "3" },
                        react_1.default.createElement(native_base_1.FormControl.Label, { _text: {
                                fontSize: 'xs',
                                fontFamily: 'Montserrat',
                                fontWeight: 'medium',
                            } }, "Email Address (we'll send you very little)"),
                        react_1.default.createElement(native_base_1.Input, { rounded: "sm", fontSize: "xs", isInvalid: !!emailError, onChangeText: handleEmailChange, InputLeftElement: react_1.default.createElement(native_base_1.Icon, { as: react_1.default.createElement(icons_1.AntDesign, { name: "mail" }), size: 5, ml: "2", color: "muted.400" }), placeholder: "Email" }),
                        !emailError && (react_1.default.createElement(native_base_1.FormControl.HelperText, null, "You'll use it for login")),
                        !!emailError && (react_1.default.createElement(native_base_1.FormControl.ErrorMessage, null,
                            emailError,
                            "xxxx"))),
                    react_1.default.createElement(native_base_1.FormControl, { mt: "3" },
                        react_1.default.createElement(native_base_1.FormControl.Label, { _text: {
                                fontSize: 'xs',
                                fontFamily: 'Montserrat',
                                fontWeight: 'medium',
                            } }, "Password"),
                        react_1.default.createElement(native_base_1.Input, { type: showPassword ? 'text' : 'password', rounded: "sm", fontSize: "xs", isInvalid: !!emailError, onChangeText: handlePasswordChange, InputRightElement: react_1.default.createElement(native_base_1.Button, { _text: {
                                    fontFamily: 'Montserrat',
                                }, size: "xs", rounded: "none", w: "1/6", h: "full", onPress: handleShowHide }, showPassword ? 'Hide' : 'Show'), InputLeftElement: react_1.default.createElement(native_base_1.Icon, { as: react_1.default.createElement(icons_1.AntDesign, { name: "key" }), size: 5, ml: "2", color: "muted.400" }), placeholder: "Password" }),
                        !passwordError && (react_1.default.createElement(native_base_1.FormControl.HelperText, null, "Good to keep things tight as you can")),
                        !!passwordError && (react_1.default.createElement(native_base_1.FormControl.ErrorMessage, null, passwordError)))),
                react_1.default.createElement(native_base_1.Popover.Footer, null,
                    react_1.default.createElement(native_base_1.Button.Group, null,
                        react_1.default.createElement(native_base_1.Button, { _text: {
                                fontFamily: 'Montserrat',
                            }, isLoading: saving, onPress: () => {
                                resetValues();
                                setIsOpen(false);
                            }, colorScheme: "coolGray", variant: "ghost" }, "Cancel"),
                        react_1.default.createElement(native_base_1.Button, { _text: {
                                fontFamily: 'Montserrat',
                            }, isLoading: saving, onPress: () => {
                                console.log('PRESSING GO SAVE SUBMIT');
                                submitSaveRequest();
                            }, colorScheme: "lightBlue" }, "Save")))))));
};
exports.default = AccountSignUp;
