"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessages = exports.getMessagingToken = exports.hasMessagingPermission = exports.saveTokenToDatabase = void 0;
const luxon_1 = require("luxon");
const QueryTypes_1 = require("./QueryTypes");
const messagesCollection = 'messages';
const saveTokenToDatabase = (firebase, token) => __awaiter(void 0, void 0, void 0, function* () {
    if (token) {
        return yield firebase.saveTokenToDatabase(token);
    }
    else {
        return undefined;
    }
});
exports.saveTokenToDatabase = saveTokenToDatabase;
const hasMessagingPermission = (firebase) => __awaiter(void 0, void 0, void 0, function* () {
    // import messaging from '@react-native-firebase/messaging';
    return yield firebase.hasMessagingPermission();
});
exports.hasMessagingPermission = hasMessagingPermission;
const getMessagingToken = (firebase) => __awaiter(void 0, void 0, void 0, function* () {
    // import messaging from '@react-native-firebase/messaging';
    return yield firebase.getMessagingToken();
});
exports.getMessagingToken = getMessagingToken;
const getMessages = (firebase, userID, limit = 10, startAfter = undefined) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        //firebase.query( claimCollection, [ new SapporoWhereQuery('withdrawn', '==', false )])
        const collectionPath = `users/${userID}/${messagesCollection}`;
        const query = new QueryTypes_1.SapporoQuery(collectionPath);
        query.addWhere(new QueryTypes_1.SapporoWhereQuery('active', '==', true));
        query.limit = new QueryTypes_1.SapporoQueryLimit(limit);
        if (startAfter) {
            query.startAfter = new QueryTypes_1.SapproStartAfter(startAfter);
        }
        query.orderBy = new QueryTypes_1.SapporoQueryOrderBy('createdAt', 'desc');
        // let pageToken;
        // let pullSize = 0;
        const queryResults = yield firebase.query(query);
        const { data, pageToken } = queryResults;
        const messages = data.map((d) => {
            const mess = Object.assign({ id: d.id }, d.data);
            mess.createdAt = luxon_1.DateTime.fromJSDate(mess.createdAt.toDate());
            return mess;
        });
        return {
            lastItemToken: pageToken,
            data: messages,
        };
    }
    catch (err) {
        console.log('FAIL', err);
        return undefined;
    }
});
exports.getMessages = getMessages;
