"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SapporoClaim = exports.SapporoSignificance = void 0;
class SapporoSignificance {
    constructor(i, s, r) {
        this.idiocy = i;
        this.spirit = s;
        this.reach = r;
    }
}
exports.SapporoSignificance = SapporoSignificance;
class SapporoClaim {
    constructor(head, longDesc, latLng, idt, sc, rch, app, userID, user) {
        this.header = head;
        this.description = longDesc;
        this.location = {
            latitude: latLng.latitude,
            longitude: latLng.longitude,
        };
        this.idiocy = idt;
        this.spirit = sc;
        this.reach = rch;
        this.approved = app;
        this.withdrawn = false;
        this.userID = userID || (user || {}).uid;
        this.user = user;
    }
    getUser() {
        return this.user;
    }
    toRelativeTimeString() {
        return this.createdAt ? `${this.createdAt.toRelative()}` : '';
    }
    toLocalTimeString() {
        return this.createdAt
            ? `${this.createdAt.toFormat('MM/dd/yyyy HH:mm:ss')}`
            : '';
    }
    isActive() {
        return this.approved && !!this.activeStart && !this.activeEnd;
    }
    toActiveString() {
        if (this.activeStart && this.activeEnd) {
            return `${this.activeStart.toFormat('MM/dd/yy HH:mm')} to ${this.activeEnd.toFormat('MM/dd/yy HH:mm')}`;
        }
        else if (this.activeStart && !this.activeEnd) {
            return `${this.activeStart.toFormat('MM/dd/yy HH:mm')} (${this.activeStart.toRelative()})`;
        }
        return undefined;
    }
}
exports.SapporoClaim = SapporoClaim;
