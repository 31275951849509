"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLastClaim = exports.subscribeToClaim = void 0;
const SapporoClaim_1 = require("../SapporoClaim");
const QueryTypes_1 = require("./QueryTypes");
const getClaimHistory_1 = require("./getClaimHistory");
const subscribeToClaim = (firebase, claimID, onUpdate) => {
    if (claimID) {
        return firebase.subscribeToDoc(getClaimHistory_1.claimCollection, claimID, onUpdate);
    }
    else {
        return () => {
            console.log('Unsubscribe Claim NOOP');
        };
    }
};
exports.subscribeToClaim = subscribeToClaim;
const getLastClaim = (firebase, userID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let query = new QueryTypes_1.SapporoQuery(getClaimHistory_1.claimCollection);
        query.addWhere(new QueryTypes_1.SapporoWhereQuery('approved', '==', true));
        query.addWhere(new QueryTypes_1.SapporoWhereQuery('withdrawn', '==', false));
        query.orderBy = new QueryTypes_1.SapporoQueryOrderBy('createdAt', 'desc');
        query.limit = new QueryTypes_1.SapporoQueryLimit(1);
        // let pageToken;
        // let pullSize = 0;
        const queryResults = yield firebase.query(query);
        const { data, pageToken } = queryResults;
        const claims = data.map((d) => (0, getClaimHistory_1.getSapporoClaimFromDoc)(d));
        const claim = claims.length > 0 ? claims[0] : undefined;
        if (claim) {
            yield (0, getClaimHistory_1.populateUsers)(firebase, [claim]);
            yield (0, getClaimHistory_1.populateReaction)(firebase, userID, [claim]);
            const newClaim = new SapporoClaim_1.SapporoClaim(claim.header, claim.description, claim.location, claim.idiocy, claim.spirit, claim.reach, claim.approved, claim.userID, claim.user);
            newClaim.id = claim.id;
            newClaim.createdAt = claim.createdAt;
            newClaim.activeStart = claim.activeStart;
            newClaim.activeEnd = claim.activeEnd;
            newClaim.approvedAt = claim.approvedAt;
            newClaim.approvedBy = claim.approvedBy;
            newClaim.reactionScore = claim.reactionScore || 0;
            newClaim.reactionCount = claim.reactionCount || 0;
            newClaim.userReaction = claim.userReaction;
            newClaim.withdrawn = claim.withdrawn;
            return newClaim;
        }
        return claim;
    }
    catch (err) {
        console.log('FAIL', err);
        return undefined;
    }
});
exports.getLastClaim = getLastClaim;
exports.default = exports.getLastClaim;
