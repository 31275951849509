"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageContext = void 0;
const react_1 = __importStar(require("react"));
const native_base_1 = require("native-base");
const react_query_1 = require("react-query");
//import auth from '@react-native-firebase/auth';
// import firestore from '@react-native-firebase/firestore';
const getUser_1 = require("./auth/getUser");
const getUserMessages_1 = require("./storage/getUserMessages");
const Firebase_1 = require("./Firebase");
exports.MessageContext = (0, react_1.createContext)(undefined);
const MessageHandler = ({ children, enabled }) => {
    const user = (0, react_1.useContext)(getUser_1.UserContext);
    const firebase = (0, react_1.useContext)(Firebase_1.FirebaseContext);
    const [message, setMessage] = (0, react_1.useState)(undefined);
    const [isOpen, setIsOpen] = (0, react_1.useState)(true);
    const onClose = () => setIsOpen(false);
    (0, react_1.useEffect)(() => {
        const check = () => __awaiter(void 0, void 0, void 0, function* () {
            if (enabled) {
                // xxxxx get messaging token 
                // xxxxx xxxxx
                const hasPermission = yield (0, getUserMessages_1.hasMessagingPermission)(firebase);
                if (hasPermission) {
                    const token = yield (0, getUserMessages_1.getMessagingToken)(firebase);
                    (0, getUserMessages_1.saveTokenToDatabase)(firebase, token);
                }
                else {
                    console.log('MESSAGE:  NO MESSAGE TO THIS BROWSER');
                }
            }
        });
        check();
    }, [enabled]);
    const { isLoading, isError, isSuccess, isIdle, data, error, isFetching } = (0, react_query_1.useQuery)('userMessages', () => {
        if (!user) {
            return new Promise((resolve) => {
                resolve([]);
            });
        }
        else {
            return (0, getUserMessages_1.getMessages)(firebase, user.uid);
        }
    });
    const userMessages = data ? data.data : [];
    const value = { isLoading, isError, isSuccess, isIdle, data, error, isFetching };
    //   useEffect(() => {
    //     const unsubscribe = messaging().onMessage(async remoteMessage => {
    //       console.log('A new FCM message arrived!', JSON.stringify(remoteMessage));
    //       if (remoteMessage.data && Object.keys(remoteMessage.data).length > 0) {
    //         console.log('DATA!');
    //         const {data} = remoteMessage;
    //         const messageData = {
    //           ...data,
    //           messageId: remoteMessage.messageId,
    //         };
    //         setMessage(messageData);
    //       } else {
    //         setMessage(remoteMessage);
    //       }
    //     });
    //     return unsubscribe;
    //   }, []);
    const cancelRef = react_1.default.useRef(null);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(exports.MessageContext.Provider, { value: message },
            children,
            enabled && userMessages && userMessages.length > 0 && (react_1.default.createElement(native_base_1.Center, null,
                react_1.default.createElement(native_base_1.AlertDialog, { leastDestructiveRef: cancelRef, isOpen: isOpen },
                    react_1.default.createElement(native_base_1.AlertDialog.Content, null,
                        react_1.default.createElement(native_base_1.AlertDialog.Header, null, userMessages[0].header),
                        react_1.default.createElement(native_base_1.AlertDialog.Body, null, userMessages[0].body),
                        react_1.default.createElement(native_base_1.AlertDialog.Footer, null,
                            react_1.default.createElement(native_base_1.Button.Group, { space: 2 },
                                react_1.default.createElement(native_base_1.Button, { onPress: onClose }, "OK"))))))))));
};
exports.default = MessageHandler;
