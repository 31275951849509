"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionsContext = void 0;
const react_1 = __importStar(require("react"));
//import {useQuery, QueryClient, QueryClientProvider} from 'react-query';
const react_query_1 = require("react-query");
const getReactions_1 = require("./storage/getReactions");
const Firebase_1 = require("./Firebase");
exports.ReactionsContext = react_1.default.createContext([]);
const ReactionList = ({ children }) => {
    const firebase = (0, react_1.useContext)(Firebase_1.FirebaseContext);
    const queryResults = (0, react_query_1.useQuery)('reactions', () => {
        return (0, getReactions_1.getReactions)(firebase);
    });
    const { isSuccess, data, isFetched } = queryResults;
    const reactions = isSuccess && isFetched && isSuccess ? [...data] : [];
    return (react_1.default.createElement(exports.ReactionsContext.Provider, { value: reactions }, children));
    // return (
    //   <QueryClientProvider client={queryClient} contextSharing={true}>
    //   <ReactionsContext.Provider value={reactions}>
    //     {children}
    //   </ReactionsContext.Provider>
    //   </QueryClientProvider>
    // );
};
exports.default = ReactionList;
