"use strict";
// orange
// const backgroundPrimary='#FF5226';
// const backgroundSecondary='#BD3326';
Object.defineProperty(exports, "__esModule", { value: true });
exports.backgroundSecondary = exports.backgroundPrimary = void 0;
// blue
const backgroundPrimary = '#5F8FFF';
exports.backgroundPrimary = backgroundPrimary;
const backgroundSecondary = '#4652D7';
exports.backgroundSecondary = backgroundSecondary;
exports.backgroundPrimary = backgroundPrimary;
exports.backgroundSecondary = backgroundSecondary;
