// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {getAuth, signOut, signInWithEmailAndPassword, signInAnonymously, onAuthStateChanged, EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import {
  getFirestore,
  collection,
  orderBy,
  startAfter,
  limit,
  query,
  where,
  WhereFilterOp,
  OrderByDirection,
  getDocs,
  getDoc,
  doc,
  GeoPoint,
  serverTimestamp,
  setDoc,
  addDoc,
  onSnapshot,
  deleteDoc,
} from 'firebase/firestore';
import {
  SapporoQuery,
  SapporoWhereQuery,
} from '@sapporogo/core/storage/QueryTypes';
import { LatLong, SapporoClaim } from '@sapporogo/core/SapporoClaim';
import { SapporoUser } from '@sapporogo/core/SapporoUser';
import { firebaseConfig, vapidKey } from './firebaseConfig';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore();
const messaging = getMessaging(app);
export const auth = getAuth();

onMessage(messaging, (payload: any) => {
  console.log('[firebase-messaging] Received foreground message ', payload);
  
})

const saveTokenToDatabase = async (token: string) => {
      // Assume user is already signed in
      const userId = auth.currentUser?.uid;
      if (userId) {
        const path = `users/${userId}/tokens`;
        const userQuery = new SapporoQuery(path);
        userQuery.addWhere( new SapporoWhereQuery('token', '==', token))
        const matching = await runQuery(userQuery);
        const now = new Date();
        if ( matching && matching.length > 0 ) {
          await saveDocument( matching[0].ref, { updatedAt: now}, true )
        } else {
          await saveDocument( path, {
            userID: userId,
            createdAt: now,
            updatedAt: now,
            token: token,
          }, false);
        }
      }

}

const hasMessagingPermission = async () => {
    return await getToken(messaging, { vapidKey}).then((currentToken) => {
      if (currentToken) {
        return true;
      } else {
        // Show permission request UI
        return false;
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return false
    });
}

const getMessagingToken = async () => {
  return await getToken(messaging, { vapidKey }).then((currentToken) => {
    if (currentToken) {
      return currentToken;
    } else {
      return undefined;
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    return undefined
  });
}

const metaDataToDB = ( metaData: any, location: LatLong): Object => {
  const newValues: any = {
    ...metaData,
  };
  
  if (location) {
    newValues.location = new GeoPoint(
      Number(location.latitude),
      Number(location.longitude),
    );
  }

  return newValues;
}

const getDocRef = (docPath: string) => {
  const pathElements = docPath.split('/').filter( f => f.length > 0 );
  if ( pathElements.length % 2 === 1 ) {
    return undefined; // odd number of items means it's a collection
  }
  const id = pathElements.pop();
  const first = pathElements.shift();
  if (first && id) {
    const collectionRef = collection(firestore, first, ...pathElements);
    const docRef = doc(collectionRef, id);
    return docRef;
  } else {
    return undefined;
  }
};

const runQuery = async (queryParts: SapporoQuery): Promise<any> => {
  const collectionRef = collection(firestore, queryParts.path);
  const queryParams = [];
  if (queryParts.wheres) {
    queryParams.push(
      ...queryParts.wheres.map((wh: SapporoWhereQuery) =>
        where(wh.field, wh.comparator as WhereFilterOp, wh.value),
      ),
    );
  }
  if (queryParts.limit) {
    queryParams.push(limit(queryParts.limit.limit));
  }
  if (queryParts.orderBy) {
    queryParams.push(
      orderBy(
        queryParts.orderBy.field,
        queryParts.orderBy.direction as OrderByDirection,
      ),
    );
  }
  if (queryParts.startAfter) {
    queryParams.push(startAfter(queryParts.startAfter.token));
  }
  const q = query(collectionRef, ...queryParams);
  let data: any[] = [];
  let pageToken;
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots
    data.push({
      id: doc.id,
      ref: doc.ref,
      data: {...doc.data()},
    });
  });
  return {data, pageToken};
};


const userNameOK = (user: SapporoUser, displayName: string, data: any) => {
  if ( displayName.length === 0 ) {
    return false;
  }
  if ( data.length === 0 ) { 
    return true;
  }
  if ( data.length === 1 ) {
    const other = data[0];
    const { id } = other;
    if ( user.uid === id ) {
      return true;
    } else {
      return false;
    }
  }
  return false; // if there are more than 1 with the same name, get out of here!
}

const logout = async () => {
  await signOut(auth);
}

const loginUser = async (email?:string, password?:string) => {
  if ( email && password ) {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if ( response.user ) {
        return {
          success: true,
          user: response.user
        }
      }
    } catch ( error: any ) {
      const errors = {
        general: `Failed: ${error.message}` 
      }
      return {
        success: false,
        errors
      }
    }
  } else {
    const errors = {
      general: 'Need email and password'
    }
    return {
      success: false,
      errors
    }
  }

}

const upgradeUser = async ( user?: SapporoUser, displayName?:string, email?:string, password?:string ) => {
  if ( user && displayName && email && password && auth.currentUser) {
    try {
      let query = new SapporoQuery(`users`);
      query.addWhere( new SapporoWhereQuery('displayName', '==', displayName ) );
      const queryResults = await runQuery(query);
      const { data } = queryResults;
      const isOkToContinue = userNameOK(user, displayName, data)
      if ( isOkToContinue ) {
        const credential = EmailAuthProvider.credential(email, password);
        const usercred = await linkWithCredential(auth.currentUser, credential);
        const user = usercred.user;
        console.log("Anonymous account successfully upgraded", user);  

        // set the display name
        const path = `users/${user.uid}`;
        const docRef = getDocRef(path);
        if ( docRef ) {
          await setDoc(docRef, { displayName, isAnonymous: false }, { merge: true } );
        }

        return {
          success: true,
          user,
        }
      } else {
        const errors: any = {}
        errors.displayName = 'You can not use that display name. Sorry.';
        return {
          success: false,
          errors
        }
      }

  
    } catch ( error: any ) {
      const errors:any = {}
      errors.general = error.message;      
      console.log("Error upgrading anonymous account", error);
      return {
        success: false,
        errors
      }  

    }
  } else {
    const errors: any = {};
    if ( !user ) {
      errors.general = 'Can not act witout user';
    }
    if ( !auth.currentUser ) {
      errors.general = 'Can not act witout user';
    }
    if (!email) {
      errors.email = 'Must provide email address'
    }
    if (!displayName) {
      errors.displayName = 'Must provide display name'
    }
    if (!password) {
      errors.password = 'Must provide display name'
    }

    return {
      success: false,
      errors
    }
  }
}

const listenOnAuthStateChanged = ( callback: any ) => {
  onAuthStateChanged(auth, callback)
}

const signInAsAnonymous = () => {
  return signInAnonymously(auth);
}

const userToDB = (user:SapporoUser): Object => {
  const dbObj: any = {};
  if (user.createdAt) {
    dbObj.createdAt = user.createdAt.toJSDate();
  }
  if (user.createdAt) {
    dbObj.displayName = user.displayName;
  }
  if (user.uid) {
    dbObj.uid = user.uid;
  }
  if (user.phoneNumber) {
    dbObj.phoneNumber = user.phoneNumber;
  }
  if (user.photoURL) {
    dbObj.photoURL = user.photoURL;
  }
  if (user.email) {
    dbObj.email = user.email;
  }
  if (user.displayName) {
    dbObj.displayName = user.displayName;
  }
  dbObj.isAnonymous = user.isAnonymous || false;
  if (user.defaultLocation) {
    dbObj.defaultLocation = new GeoPoint(
      Number(user.defaultLocation.latitude),
      Number(user.defaultLocation.longitude),
    );
  }
  dbObj.hideAds = !!user.hideAds;
  dbObj.emailVerified = !!user.emailVerified;

  return dbObj;
}



const claimToDB = (claim: SapporoClaim): Object => {
  return {
    header: claim.header,
    description: claim.description,
    location: new GeoPoint(
      Number(claim.location.latitude),
      Number(claim.location.longitude),
    ),
    idiocy: claim.idiocy,
    spirit: claim.spirit,
    reach: claim.reach,
    approved: claim.approved,
    withdrawn: claim.withdrawn,
    reactionScore: claim.reactionScore || 0,
    reactionCount: claim.reactionCount || 0,
  };
}



const getServerTimestampValue = () => {
  return serverTimestamp();
}

const saveDocument = async ( path: string, data: any, overwrite: boolean ) => {
  const docRef = getDocRef(path);
  if ( docRef ) {
    await setDoc(docRef, data, { merge: !overwrite } );
  } else {
    // must be a collection
    const collectionRef = getCollectionRef(path);
    if ( collectionRef ) {
      await addDoc( collectionRef, data );
    }
  }
}

const getCollectionRef = (docPath: string) => {
  const pathElements = docPath.split('/').filter( f => f.length > 0 );
  if ( pathElements.length % 2 === 0 ) {
    return undefined; // even number of items means it's a doc
  }
  const first = pathElements.shift();
  if (first) {
    const collectionRef = collection(firestore, first, ...pathElements);
    return collectionRef;
  } else {
    return undefined;
  }
};


const deleteDocument = async ( path:string ) => {
  const pathElements = path.split('/');
  const id = pathElements.pop();
  const first = pathElements.shift();
  if (first && id) {
    const collectionRef = collection(firestore, first, ...pathElements);
    const docRef = doc(collectionRef, id);
    await deleteDoc(docRef);
  }  

  
}

const loadDoc = async (path: string) => {
  const pathElements = path.split('/');
  const id = pathElements.pop();
  const first = pathElements.shift();
  if (first && id) {
    const collectionRef = collection(firestore, first, ...pathElements);
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ref: docSnap.ref,
        data: docSnap.data(),
      };
    } else {
      return undefined;
    }
  }
};

const saveUser = async ( user: SapporoUser) => {
  if ( user && user.uid ) {
    const userDB: any = userToDB( user );
    const path = `users/${user.uid}`;
    await saveDocument(path, userDB, true)
    const retUser = await loadDoc(path);
    if ( retUser ) {
      retUser.data.hideAds = retUser.data.hideAds || false;
    }  
    return retUser;  
  } else {
    return undefined;
  }
}

const subscribeToDoc = ( coll: string, docID:string, onUpdate: (snapshot:any) => any ) => {
  const pathElements = coll.split('/');
  const first = pathElements.shift();
  if ( first && docID ) {
    const collectionRef = collection(firestore, first, ...pathElements);
    const docRef = doc(collectionRef, docID);
    return onSnapshot( docRef, onUpdate );
  } else {
    return () => {
      console.log('NOOP unsubscribe');
    }
  }


}


const subscribeToCollection = ( coll: string, onUpdate: (snapshot:any) => any ) => {
  return onSnapshot( collection( firestore, coll), onUpdate );
}

const getDeviceData = async () => {
  return undefined;
  // const deviceUniqueID = DeviceInfo.getUniqueId();
  // const brand = DeviceInfo.getBrand();
  // const buildNumber = DeviceInfo.getBuildNumber();
  // const bundleId = DeviceInfo.getBundleId();
  // const deviceId = DeviceInfo.getDeviceId();
  // const deviceType = DeviceInfo.getDeviceType();
  // const model = DeviceInfo.getModel();
  // const version = DeviceInfo.getReadableVersion();
  // const systemName = DeviceInfo.getSystemName();
  // const systemVersion = DeviceInfo.getSystemVersion();
  // const tablet = DeviceInfo.isTablet();
  // const notch = DeviceInfo.hasNotch();
  // const emulator = await DeviceInfo.isEmulator();
  // const installTime = await DeviceInfo.getFirstInstallTime();
  // const fontScale = await DeviceInfo.getFontScale();
  // const lastUpdateTime = await DeviceInfo.getLastUpdateTime();
  // const featureSet = await DeviceInfo.getSystemAvailableFeatures();

  // return {
  //   deviceUniqueID,
  //   brand,
  //   buildNumber,
  //   bundleId,
  //   deviceId,
  //   deviceType,
  //   model,
  //   version,
  //   systemName,
  //   systemVersion,
  //   tablet,
  //   notch,
  //   emulator,
  //   installTime,
  //   fontScale,
  //   lastUpdateTime,
  //   featureSet,
  // };
};


export const fullFirebase = {
  app,
  analytics,
  firestore,
  subscribeToDoc: ( collection: string, docID: string, onUpdate: (snapshot: any) => any ) => subscribeToDoc(collection, docID, onUpdate ),
  subscribeToCollection: ( collection: string, onUpdate: (snapshot: any) => any ) => subscribeToCollection( collection, onUpdate),
  upsert: (path:string, data: any, overwrite?: boolean) => saveDocument(path,data,overwrite||false),
  query: (query: SapporoQuery) => runQuery(query),
  getDoc: (path: string) => loadDoc(path),
  deleteDoc: (path: string) => deleteDocument(path),
  docRef: (path: string) => getDocRef(path),
  serverTimestampValue: () => getServerTimestampValue(),
  listenOnAuthStateChanged,
  signInAsAnonymous,
  userToDB,
  claimToDB,
  metaDataToDB,
  upgradeUser,
  loginUser,
  logout,
  saveUser,
  hasMessagingPermission,
  getMessagingToken,
  saveTokenToDatabase,
  getDeviceData
};
