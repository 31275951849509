"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const native_base_1 = require("native-base");
const ReactionList_1 = require("./ReactionList");
const icons_1 = require("@native-base/icons");
const UI_1 = require("./UI");
const SapporoLikePanel = ({ userReaction, reactionCount, reactionScore, userScored, userScoreValue, onPress, saveScore, onScoreChange, busy, staticOnly = false, }) => {
    const reactions = (0, react_1.useContext)(ReactionList_1.ReactionsContext);
    const backgroundPrimary = '#5F8FFF';
    const [isScorePopoverOpen, setIsScorePopoverOpen] = (0, react_1.useState)(false);
    const [isUserPopoverOpen, setIsUserPopoverOpen] = (0, react_1.useState)(false);
    const getLikeIcon = () => {
        if (staticOnly) {
            return (react_1.default.createElement(native_base_1.Icon, { as: icons_1.AntDesign, name: userScoreIndicatorIcon, size: "6", color: userScoreIndicatorColor }));
        }
        return (react_1.default.createElement(native_base_1.IconButton, { m: 0, p: 0, onPress: onPress, icon: react_1.default.createElement(native_base_1.Icon, { as: icons_1.AntDesign, name: userScoreIndicatorIcon, size: "6", color: userScoreIndicatorColor }) }));
    };
    const getReactionForValue = (value) => {
        if (reactions && reactions.length) {
            const matchingReaction = reactions.reduce((chosen, nextReaction) => {
                if (value >= nextReaction.value) {
                    return nextReaction;
                }
                else {
                    return chosen;
                }
            }, undefined);
            return matchingReaction ? matchingReaction.message : '';
        }
        else {
            console.log('reactions empty');
            return '';
        }
    };
    const [userScoreMessage, setUserScoreMessage] = (0, react_1.useState)(userReaction ? getReactionForValue(userReaction.score) : undefined);
    const userScoreIndicatorIcon = userScored ? 'like1' : 'like2';
    const userScoreIndicatorColor = UI_1.backgroundSecondary;
    return (react_1.default.createElement(native_base_1.VStack, null,
        userScored && !staticOnly && (react_1.default.createElement(native_base_1.VStack, { alignItems: "center", mt: 3 },
            react_1.default.createElement(native_base_1.Text, { fontWeight: "500", fontFamily: 'Montserrat' },
                "Your Reaction ",
                userScoreValue ? userScoreValue : '',
                " (Global:",
                ' ',
                reactionScore.toFixed(1),
                ")"),
            busy && react_1.default.createElement(native_base_1.Spinner, { color: backgroundPrimary }),
            !busy && (react_1.default.createElement(native_base_1.Slider, { w: "80%", maxW: "400", size: "lg", minValue: 1, maxValue: 100, defaultValue: userScoreValue || 1, colorScheme: "blue", onChange: v => {
                    if (!staticOnly) {
                        onScoreChange(Math.floor(v));
                        setUserScoreMessage(getReactionForValue(v));
                    }
                }, onChangeEnd: v => {
                    if (!staticOnly) {
                        const value = Math.floor(v);
                        console.log('Value End', value);
                        setUserScoreMessage(getReactionForValue(value));
                        saveScore(value);
                    }
                } },
                react_1.default.createElement(native_base_1.Slider.Track, null,
                    react_1.default.createElement(native_base_1.Slider.FilledTrack, null)),
                react_1.default.createElement(native_base_1.Slider.Thumb, null))),
            !busy && (react_1.default.createElement(native_base_1.Text, { fontWeight: "200", fontSize: 10, fontStyle: "italic", fontFamily: 'Montserrat' }, userScoreMessage || '')))),
        react_1.default.createElement(native_base_1.HStack, { mt: 3, m: 0, p: 0, justifyContent: "space-between" },
            react_1.default.createElement(native_base_1.HStack, { alignItems: "space-between" }, getLikeIcon()),
            react_1.default.createElement(native_base_1.HStack, { alignItems: "center" },
                react_1.default.createElement(native_base_1.Text, { fontWeight: "200", fontSize: "12" }, reactionCount),
                react_1.default.createElement(native_base_1.Popover, { isOpen: isUserPopoverOpen, onClose: () => {
                        setIsUserPopoverOpen(false);
                    }, trigger: triggerProps => {
                        return (react_1.default.createElement(native_base_1.IconButton, Object.assign({}, triggerProps, { onPress: () => {
                                setIsUserPopoverOpen(!isUserPopoverOpen);
                            }, icon: react_1.default.createElement(native_base_1.Icon, { pr: 1, as: icons_1.AntDesign, name: "user", size: "6", color: UI_1.backgroundSecondary }) })));
                    } },
                    react_1.default.createElement(native_base_1.Popover.Content, { borderWidth: 1, borderColor: UI_1.backgroundSecondary, w: "56" },
                        react_1.default.createElement(native_base_1.Popover.Arrow, { bg: UI_1.backgroundSecondary }),
                        react_1.default.createElement(native_base_1.Popover.CloseButton, null),
                        react_1.default.createElement(native_base_1.Popover.Header, null, "Users"),
                        react_1.default.createElement(native_base_1.Popover.Body, null, "Number of fellow guards' that have posted a reaction score"))),
                react_1.default.createElement(native_base_1.Text, { fontWeight: "200", fontSize: "12" }, reactionScore.toFixed(1)),
                react_1.default.createElement(native_base_1.Popover, { isOpen: isScorePopoverOpen, onClose: () => {
                        setIsScorePopoverOpen(false);
                    }, trigger: triggerProps => {
                        return (react_1.default.createElement(native_base_1.IconButton, Object.assign({}, triggerProps, { onPress: () => {
                                setIsScorePopoverOpen(!isScorePopoverOpen);
                            }, icon: react_1.default.createElement(native_base_1.Icon, { as: icons_1.AntDesign, name: "areachart", size: "6", color: UI_1.backgroundSecondary }) })));
                    } },
                    react_1.default.createElement(native_base_1.Popover.Content, { borderWidth: 1, borderColor: UI_1.backgroundSecondary, w: "56" },
                        react_1.default.createElement(native_base_1.Popover.Arrow, { bg: UI_1.backgroundSecondary }),
                        react_1.default.createElement(native_base_1.Popover.CloseButton, null),
                        react_1.default.createElement(native_base_1.Popover.Header, null, "Reaction Score"),
                        react_1.default.createElement(native_base_1.Popover.Body, null, "Fellow guards' reaction on a scale of 1-100. What's the scale based on? Severity? Style? Hard to say. You just know a '100' claim from a '1'.")))))));
};
exports.default = SapporoLikePanel;
