"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimHistoryContext = void 0;
const react_1 = __importStar(require("react"));
const react_query_1 = require("react-query");
const getClaimHistory_1 = require("./storage/getClaimHistory");
const Firebase_1 = require("./Firebase");
const historyQueryID = 'claimHistory';
exports.ClaimHistoryContext = react_1.default.createContext(undefined);
const ClaimHistory = ({ children, user }) => {
    const firebase = (0, react_1.useContext)(Firebase_1.FirebaseContext);
    const queryClient = (0, react_query_1.useQueryClient)();
    (0, react_1.useEffect)(() => {
        const unsub = (0, getClaimHistory_1.subscribeToHistory)(firebase, snapshot => {
            queryClient.invalidateQueries(historyQueryID);
            snapshot.docChanges().forEach((change) => {
                console.log('Updates', !!change);
                // if (change.type === 'added') {
                //   console.log('New CLAIM: ', change.doc.data());
                // }
                // if (change.type === 'modified') {
                //   console.log('Modified Claim: ', change.doc.data());
                // }
                // if (change.type === 'removed') {
                //   console.log('Removed Claim: ', change.doc.data());
                // }
            });
        });
        return unsub;
    }, []);
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status, } = (0, react_query_1.useInfiniteQuery)(historyQueryID, () => {
        if (!user) {
            return new Promise(resolve => {
                resolve(undefined);
            });
        }
        else {
            return (0, getClaimHistory_1.getClaimHistory)(firebase, user === null || user === void 0 ? void 0 : user.uid, 10, undefined);
        }
    }, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage) {
                return lastPage.lastItemToken;
            }
            else {
                return undefined;
            }
        },
    });
    const isLoading = status === 'loading';
    const buildClaimHistoryFromData = (claimData) => {
        const { pages } = claimData;
        const history = [];
        if (pages && pages.length > 0) {
            pages.forEach((page) => {
                if (page) {
                    const { data: pageData } = page;
                    history.push(...pageData);
                }
            });
        }
        return history;
    };
    const history = !isLoading && !!data ? buildClaimHistoryFromData(data) : [];
    const value = {
        error,
        isFetching,
        isFetchingNextPage,
        hasNextPage,
        status,
        fetchNextPage,
        history,
    };
    return (react_1.default.createElement(exports.ClaimHistoryContext.Provider, { value: value }, children));
};
exports.default = ClaimHistory;
