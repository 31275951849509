import { useNavigate } from 'react-router-dom';
import { Box } from 'native-base';
import ClaimSapporoForm from '@sapporogo/core/ClaimSapporoForm';
import {LatLong} from '@sapporogo/core/SapporoClaim';
import {SapporoUser} from '@sapporogo/core/SapporoUser';


type WebClaimSapporoFormProps = {
    asModal?: boolean;
    showClaimSapporo: boolean; // show the modal?
    setShowClaimSapporo: (show: boolean) => any; // function to call to toggle
    user?: SapporoUser;
    victory: any; // graphing lib reference
    getUIComponents: () => any;
    getImage: (name: string) => any;
    getLocationViewWithEdit: (
      location: LatLong,
      markerLocation: LatLong,
      locationUpdated: (location: any) => any,
    ) => any;
  };
  

const WebClaimSapporoForm = ({
    showClaimSapporo,
    setShowClaimSapporo,
    user,
    victory,
    getImage,
    getUIComponents,
    getLocationViewWithEdit,
    asModal = false,
  }: WebClaimSapporoFormProps) => {
    const navigate = useNavigate();

    return (
      <Box
      minWidth={600} 
      maxWidth={1000} 
      width={"120%"}
      flex={1}
      pl={5}
      >
        <ClaimSapporoForm
        asModal={asModal}
        getUIComponents={getUIComponents}
        victory={victory}
        getImage={getImage}
        user={user}
        actionListener={() => {
            navigate('/history');
            console.log('SAVING IT CLAIM');
        }}
        showClaimSapporo={showClaimSapporo}
        setShowClaimSapporo={setShowClaimSapporo}
        getLocationViewWithEdit={
          getLocationViewWithEdit
        }
      />
      </Box>
    )
}

export default WebClaimSapporoForm


