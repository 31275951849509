import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {NativeBaseProvider, extendTheme} from 'native-base';
import reportWebVitals from './reportWebVitals';

import fontsCSS from '@native-base/icons/FontsCSS';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
});

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(fontsCSS));
document.head.appendChild(style);

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
