import React, {useContext, useState} from 'react';
import {
  VStack,
  Text,
  Stack,
  Spacer,
  Center,
  Image,
  HStack,
  Heading,
  Box,
  HamburgerIcon,
  Menu,
  Pressable,
  Icon,
} from 'native-base';
import AccountSignUp from '@sapporogo/core/auth/AccountSignUp';
import {useNavigate, useLocation} from 'react-router-dom';
import {AntDesign} from '@native-base/icons';
import LoginView from '@sapporogo/core/auth/LoginView';
import {SapporoUser} from '@sapporogo/core/SapporoUser';
import {UserContext} from '@sapporogo/core/auth/getUser';
import {backgroundPrimary, backgroundSecondary} from '@sapporogo/core/UI';
import {FirebaseContext} from '@sapporogo/core/Firebase';

type SapporoHeaderProps = {
  getUIComponents: () => any;
  getImage: (name: string) => any;
};

type ActionItemProps = {
  action: () => any;
  label: string;
  icon: string;
  bg?: string;
  pressColor?: string;
};
const ActionItem = ({
  action,
  label,
  icon,
  bg = backgroundPrimary,
  pressColor = backgroundSecondary,
}: ActionItemProps) => {
  return (
    <Center h="16" w="40" bg={bg} rounded="md" shadow={3}>
      <Pressable flex={1} w={'100%'} h={'100%'} onPress={action}>
        {({isHovered, isPressed}) => {
          let bgColor = bg;
          if (isHovered || isPressed) {
            bgColor = pressColor;
          }
          return (
            <Box
              flex={1}
              w={'100%'}
              h={'100%'}
              maxW="96"
              borderWidth="0"
              borderRightWidth="1"
              borderBottomWidth="1"
              borderColor="white"
              shadow="3"
              bg={bgColor}
              p="2"
              rounded="8"
              style={{
                transform: [
                  {
                    scale: isPressed ? 0.96 : 1,
                  },
                ],
              }}>
              <HStack alignItems="center" space={3}>
                <Icon
                  p={1}
                  size="lg"
                  color={'#FFFFFF'}
                  as={<AntDesign name={icon} />}
                />
                <Text
                  size="mini"
                  fontWeight="600"
                  color={'#FFFFFF'}
                  fontFamily={'Montserrat'}>
                  {label}
                </Text>
              </HStack>
            </Box>
          );
        }}
      </Pressable>
    </Center>
  );
};

type NavigationItemProps = {
  label: string;
  icon: string;
  navigationAction: () => any;
};
const NavigationItem = ({navigationAction, label, icon}: NavigationItemProps) => {
  return <ActionItem label={label} icon={icon} action={navigationAction} />;
};

const SapporoHeader = ({getImage, getUIComponents}: SapporoHeaderProps) => {
  const UI = getUIComponents();
  const user = useContext<SapporoUser | undefined | null>(UserContext);
  const firebase: any = useContext(FirebaseContext);

  let location: any = useLocation();
  let from = location.state?.from?.pathname || '/';
  let navigate: any = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onMenuAction = ( destination: string ) => () => {
    console.log('Want to take action');
    console.log('Leaving', from);
    setIsOpen(false)
    navigate(destination, {replace: true});
  }

  return (
    <HStack
      minH={150}
      width={'100%'}
      borderColor={'white'}
      flex={1}
      justifyContent={'space-between'}
      p={5}
      m={0}
      rounded="lg">
        <Pressable onPress={() => {
          window.location.href = '/';
        }}>
      <HStack flex={1}>
          <Image alt="SapporoGuard Logo" source={getImage('badge')} size="sm" />
          <Heading
            pl={5}
            pb={3}
            pt={3}
            size="lg"
            color="white"
            fontFamily={'Montserrat'}>
            Sapporo
          </Heading>
      </HStack>
      </Pressable>
      <VStack flex={1} alignSelf='flex-start' justifyContent='flex-start'>
        <Box flex={1} alignSelf="flex-end" justifyContent='flex-start'>
          <Menu
            isOpen={isOpen}
            onClose={() => {
                console.log('CLOSING IT')
            }}
            w="190"
            trigger={triggerProps => {
              return (
                <Pressable
                  accessibilityLabel="More options menu"
                  {...triggerProps}
                  onPress={() => {
                      setIsOpen(!isOpen)
                  }}
                  >
                  <HamburgerIcon size="xl" color="white" />
                </Pressable>
              );
            }}>
            <Menu.Item>
              <NavigationItem navigationAction={onMenuAction('/')} label={'Home'} icon={'home'} />
            </Menu.Item>
            <Menu.Item>
              <NavigationItem
                navigationAction={onMenuAction('/about')}
                label={'About'}
                icon={'infocirlceo'}
              />
            </Menu.Item>
            <Menu.Item>
              <NavigationItem
                navigationAction={onMenuAction('/history')}
                label={'History'}
                icon={'folderopen'}
              />
            </Menu.Item>
            <Menu.Item>
              <NavigationItem
                navigationAction={onMenuAction('/claim')}
                label={'Claim it!'}
                icon={'plus'}
              />
            </Menu.Item>
            {user && !user.isAnonymous && (
              <Menu.Item>
                <ActionItem
                  bg={backgroundSecondary}
                  pressColor={backgroundPrimary}
                  action={() => {
                    console.log('LOGOUT');
                    firebase.logout();
                  }}
                  label={'Logout'}
                  icon={'logout'}
                />
              </Menu.Item>
            )}
          </Menu>
        </Box>
        {user && user.displayName && (
          <VStack flex={1} alignSelf="flex-end" justifyContent={'center'}>
              <Text
                textAlign={'center'}
                fontSize={14}
                color="#FFFFFF"
                fontFamily={'Montserrat'}>
                Hi, {user.displayName}
              </Text>
            {user && !user.isAnonymous && <Spacer p={2} height={12} />}
            {user && !!user.isAnonymous && (
              <Center p={2} height={12}>
                <HStack space={1}>
                  <AccountSignUp />
                  <LoginView />
                </HStack>
              </Center>
            )}
          </VStack>
        )}
      </VStack>
    </HStack>
  );
};

export default SapporoHeader;
