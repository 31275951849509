"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveLikeStatus = exports.saveClaim = void 0;
const getClaimHistory_1 = require("./getClaimHistory");
const saveClaim = (firebase, claim, user) => __awaiter(void 0, void 0, void 0, function* () {
    console.log('The Claim being made in', claim, user);
    if (user) {
        try {
            const save = Object.assign(Object.assign({}, firebase.claimToDB(claim)), { user: firebase.docRef(`users/${user.uid}`), createdAt: firebase.serverTimestampValue() });
            return yield firebase.upsert(`${getClaimHistory_1.claimCollection}`, save);
        }
        catch (err) {
            console.log('FAIL', err);
            return undefined;
        }
    }
    else {
        console.log('NO USER TO SAVE WITH');
    }
});
exports.saveClaim = saveClaim;
const saveLikeStatus = (firebase, claimID, userID, like, score) => __awaiter(void 0, void 0, void 0, function* () {
    console.log('Trying to like claim', claimID, userID, like, score);
    if (claimID && userID) {
        const likePath = `${getClaimHistory_1.claimCollection}/${claimID}/${getClaimHistory_1.likeCollection}/${userID}`;
        console.log('Trying to like claim', likePath);
        const previous = yield firebase.getDoc(likePath);
        // if we have a previous and we are asking to delete the like, do it
        if (!like) {
            if (previous) {
                console.log('PREVI', previous);
                yield firebase.deleteDoc(previous.ref.path);
            }
            else {
                console.log('No like to delete for ', userID, claimID, like, score);
            }
        }
        else if (like) {
            const updatedData = {
                user: firebase.docRef(`users/${userID}`),
                claim: firebase.docRef(`claims/${claimID}`),
                score: score,
                createdAt: firebase.serverTimestampValue(),
            };
            console.log('Trying to like claim SAVING', updatedData);
            yield firebase.upsert(likePath, updatedData, true);
        }
        else {
            console.log('Doing nothing');
        }
    }
    else {
        console.log('Could not work on undefined item', claimID, userID);
    }
});
exports.saveLikeStatus = saveLikeStatus;
exports.default = exports.saveClaim;
